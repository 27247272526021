html, body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    color: var(--black-color);
}

:root {
    --purple-color: #9580ff;
    --green-color: #8bff80;
    --dark-green-color: #88e680;
    --black-color: #5a5a5a;
    --red-color: #FF9580;
}

h1, h2, h3, h4 {
    font-family: SourceCodePro-Bold;
    margin-top: 0;
}

h2 {
    font-size: calc(1em + 2vw);
}

a {
    font-family: SourceCodePro;
    text-decoration: none;
    color: var(--black-color);
}

a:hover {
    border-bottom: 2px solid var(--purple-color);
}

.button:hover {
    border: unset;
    background-color: var(--red-color);
}

img {
    height: 100%;
}

@font-face {
    font-family: Inconsolata;
    src: url(fonts/Inconsolata.otf);
}

@font-face {
    font-family: SourceCodePro;
    src: url(fonts/SourceCodePro-Regular.otf);
}

@font-face {
    font-family: SourceCodePro-Bold;
    src: url(fonts/SourceCodePro-Bold.otf);
}

@font-face {
    font-family: Montserrat;
    src: url(fonts/Montserrat-Regular.otf);
}

header {
    z-index: 1;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px auto;
    height: 10vh;
    padding: 15px 0px;
}

body {
    font-family: Montserrat;
    font-size: calc(.6em + 1.9vw);
}

.button {
    font-family: SourceCodePro-Bold;
    font-size: 1.25rem;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    background-color: var(--green-color);
    border-radius: 10px;
    border: none;
    box-shadow: none;
    padding: 25px 3vw;
}

.carrots {
    color: var(--green-color);
}

.separators {
    color: var(--green-color);
}

label {
    font-family: SourceCodePro-Bold;
    color: var(--purple-color);
    background-color: #fff;
}

input {
    margin: 0px 0px 3vh 0px;
    border-color: var(--purple-color);
    padding: 5px;
}

.border {
    border: 3px solid #fff;
    border-radius: 10px;
    box-sizing: border-box;
}

.highlight {
    background-color: var(--green-color);
    color: #fff;
}

textarea {
    margin: 0px 0px 3vh 0px;
    border-color: var(--purple-color);
    padding: 5px;
    resize: none;
}

.image-separator {
    width: 20vw;
    height: auto;
    margin: 5vw 10vw;
}

.image-separator2 {
    position: relative;
    z-index: -1;
    width: 15vw;
    height: auto;
    margin-left: 3vw;
    margin-bottom: -1.25vh;
}

.section-break {
    display: flex;
    align-items: center;
}

.block {
    padding: 0px 10vw;
    margin: 3vh 0px;
}

.section-break h2 {
    /*border: 1px solid #f2f3ff;*/
    border-radius: 10px;
    padding: 0 10px;
    margin: 0;
    color: var(--purple-color);
    background-color: #fff;
    text-transform: uppercase;
}

.section-break hr {
    flex: 1;
    color: #f2f3ff;
    box-shadow: none;
    height: 2px;
    border: none;
    border-bottom: 2px solid #f2f3ff;
}

.content-block {
    padding: 3vh 0px;
}

.content-figure {
    text-align: center;
    margin: 15px auto;
}

.content h2 {
    color: var(--green-color);
    text-transform: uppercase;
}

@keyframes scale-up {
    from {
        height: 0%;
        width: 0;
        padding: 0;
    }

    to {
        height: 100%;
        width: 100%;
        padding: 50vw;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    
    to {
        transform: rotate(360deg);
    }
}

@keyframes slide-down {
    from {
        margin-top: -1000vh;
    }

    to {
        margin-top: initial;
    }
}

@keyframes fade-slide-up {
    from {
        opacity: 0;
        margin-top: 50vh;
    }

    to {
        opacity: 1;
        margin-top: 6%;
    }
}

.animatable {
    animation-play-state: paused;
}

.animate {
    animation-play-state: running;
}

#planet {
    transform-origin: 50% 50%;
    animation-name: spin;
    animation-duration: 90s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.animate--scale-up {
    animation: scale-up .25s;
}

.animate--scale-down {
    animation: scale-up reverse .25s;
}

/*
#satellite {
    transform: translate(0.000000, -4.558541);
}
*/

.service {
    animation: fade-slide-up 3s;
}
